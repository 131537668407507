import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Form from "./components/Form";
import LoginPage from "./components/LoginPage";
import ImageBackground from "./components/ImageBackground";
import "./App.css";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname !== "/" && <ImageBackground />}
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/registration" element={<Form />} />
      </Routes>
      {location.pathname !== "/" && <ImageBackground />}
    </div>
  );
}

export default App;
