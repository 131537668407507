import React, { useState } from "react";
import firebase from "../firebaseConfig";
import styles from "./login.module.css";
import { useNavigate } from "react-router-dom"; // Corrected the import
import * as XLSX from 'xlsx';
const LoginPage = () => {
  const [idCard, setIdCard] = useState("");
  const navigate = useNavigate(); // navigate is the commonly used name for this function

  const handleChange = (e) => {
    setIdCard(e.target.value);
    console.log(idCard)
  };
  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "data.xlsx");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userRef = firebase.database().ref('users').child(idCard);

    if (idCard === '0525513098') {
      const usersRef = firebase.database().ref('submissions');
      const snapshot = await usersRef.once('value');
      if (snapshot.exists()) {
        const firebaseData = snapshot.val();
        const dataArray = Object.keys(firebaseData).map(key => firebaseData[key]);
        exportToExcel(dataArray);
        return;
      }
    }


    userRef.once('value').then((snapshot) => {
      if (snapshot.exists()) {
        // If the id card exists in Firebase, redirect to the registration page
        navigate('/registration', { state: { id: idCard } });
        // use navigate instead of history.push
      } else {
        // If the id card does not exist, show an error message
        alert('This ID card number does not exist. Please try again.');
      }
    }).catch((error) => {
      // Handle any errors here
      console.error("Firebase read failed:", error);
      alert('תעודת הזהות שהקשת לא קיימת במערכת,נסה שנית או פנה למוקד התמיכה שלנו');
    });
  };

  return (
    <div style={{ height: "100vh", backgroundColor: 'rgb(88, 128, 162)', display: "flex", justifyContent: "center", alignItems: "center" }}>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <label>
            הכנס תעודת זהות :
          </label>
          <div style={{padding:10}}/>
          <input type="text" value={idCard} onChange={handleChange} className={styles.input} required style={{ display: 'block', width: '100%' }} />
        </div>
        <div>
          <button className={styles.button} style={{ display: 'block', width: '100%', padding: "10px", fontSize: "1.2em" }} type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
