import React from 'react';
import './ImageBackground.css';  // Importing the CSS

const ImageBackground = () => {
    return (
        <div className="image-container">
            <form></form>
        </div>
    );
}

export default ImageBackground;