import firebase from "firebase/app";
import "firebase/database";
import "firebase/functions";
import 'firebase/firestore';
const firebaseConfig = {
    apiKey: "AIzaSyCtoNB3HhDDW7IAloBJiEpcxmkYrPOghPU",
    authDomain: "landingpage-3aa7a.firebaseapp.com",
    projectId: "landingpage-3aa7a",
    storageBucket: "landingpage-3aa7a.appspot.com",
    messagingSenderId: "560523877105",
    appId: "1:560523877105:web:b0e7fbfa9890ff7323e750",
    measurementId: "G-ZN8H6QVS09"
  };


  firebase.initializeApp(firebaseConfig);
  export const functions = firebase.functions();

  
  export default firebase;

