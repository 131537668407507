import React, { useState,useEffect } from "react";
import firebase from "../firebaseConfig"
import styles from "./Form.module.css";



import { useLocation } from 'react-router-dom';
import Image from '../images/e363eed80b856fcfb336a0f2ff34653642096f98.jpg';
const Form = () => {
  const [formData, setFormData] = useState({שםפרטי: "", אימייל: "", שםמשפחה:"",אישורדיור:"",שםהסניף:"", מספרסניף: "",תעודתזהות:"",טלפון:"",איזור:"", זכאות:"2",מספרכרטיסים:"",הסעה:"No",מקוםהסעה:"",מספרנוסעים:"",מנותגלאט:"",כמותמנותגלאט:""});
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);
  const [showAdditionalOptions2, setShowAdditionalOptions2] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const [userData, setUserData] = useState({});
  const location = useLocation();
  const idCard = location.state.id;
const check=`+972${formData.טלפון.slice(1)}`
  useEffect(() => {
    // Code for the first side effect goes here
    const userRef = firebase.database().ref('users').child(idCard);
  
    userRef.once('value').then((snapshot) => {
      if (snapshot.exists()) {
        setUserData(snapshot.val());
      }
    }).catch((error) => {
      console.error("Firebase read failed:", error);
    });
  
    if (formData.הסעה === "Yes") {
      setShowAdditionalOptions(true);
    } else {
      setShowAdditionalOptions(false);
    }
   
  
  }, [idCard, formData.הסעה]);
  

  const handleSelectChange2 = (e) => {
    setFormData({ ...formData, הסעה: e.target.value });
  };
  const handleSelectChange3 = (e) => {
    setFormData({ ...formData, מנותגלאט: e.target.value });
    if (e.target.value === "Yes") {
      setShowAdditionalOptions2(true);
    } else {
      setShowAdditionalOptions2(false);
    }
  };
  

  const handleChangeCoki = (e) => {
    console.log(e);
    let value = e.target.checked ? 'yes' : 'no';
    setFormData({ ...formData, [e.target.name]: value });
  };
  const handleChange = (e) => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value,
      'שםפרטי': userData.שם,
      'שםמשפחה': userData['שם משפחה'],
      'מספרסניף':userData.סניף,
      'תעודתזהות':idCard
    }));
};


  const handleSelectChange = (e) => {
    setFormData({ ...formData, מקוםהסעה: e.target.value });
  };
  const handleSelectChangeArea = (e) => {
    setFormData({ ...formData, איזור: e.target.value });
  };

const callFirebaseFunction = async (functionName, data) => {
  const callable = firebase.functions().httpsCallable(functionName);
  try {
      await callable(data);
      console.log(`${functionName} was successful`);
  } catch (error) {
      console.error(`${functionName} failed:`, error);
  }
}

const updateUserRef = async (formData, idCard) => {
  const userRef = firebase.database().ref('submissions').child(idCard);
  try {
      await userRef.set(formData);
      console.log("Data saved to Firebase successfully");
  } catch (error) {
      console.error("Firebase save failed:", error);
  }
}
// const handleSubmit=async ()=>{
//   firebase.firestore().collection('messages').add({
//     to: phoneNumberToSend,
//     body: 'Hello from Firebase!'
//   });
// }
const handleSubmit = async (e) => {
  e.preventDefault();
const phoneNumberToSend=check;
console.log(` phoneNumberToSend`, phoneNumberToSend);
const emailToSendTo = formData.אימייל;
console.log(`clientEmail`,emailToSendTo);
 
const dataToSendEmail = `
שלום ${userData.שם} ${userData['שם משפחה']},

תודה על ההזמנה!
הזמנת ${formData.מספרכרטיסים} כרטיסים לאירוע שלנו.
אנו מצפים לראותך בקרוב!

אם יש לך שאלות או בירורים, אל תהסס ליצור איתנו קשר.

בברכה,
פארוצ'י
`;
  const dataToSend = {
      formData: dataToSendEmail,
      clientEmail: emailToSendTo,
  };

  try {
    console.log("Sending data to cloud function:", dataToSend)
      await Promise.all([
          callFirebaseFunction('sendEmail', dataToSend),
          updateUserRef(formData, idCard)  // Make sure idCard is accessible
      ]);
      firebase.firestore().collection('messages').add({
        from:'PARUCHI',
        to: phoneNumberToSend,
        body: `שלום ${userData.שם}, תודה על ההזמנה! הזמנת ${formData.מספרכרטיסים} כרטיסים. נתראה בקרוב!`
    });
  } catch (error) {
      console.error("An error occurred while processing:", error);
  }

  setIsSubmitted(true);
};
  
  const generateOptions = (count) => {
    let options = [];
    for (let i = 0; i <= count; i++) {
      options.push(i);
    }
    return options;
  }

  return (
<div>
{!isSubmitted ? (
    <form onSubmit={handleSubmit} style={{backgroundImage: `url(${Image})`}} className={styles.formContainer}>
  
      <div className={styles.inputContainer}>
      <div className={styles.inputGroup}>
   
        <label htmlFor="שםפרטי" className={styles.label}>
          שם פרטי
        </label>
        <input
       type="text" value={userData.שם} readOnly
          className={styles.input}
          
          required
          
        />
          <label htmlFor="שםמשפחה" className={styles.label}>
        שם משפחה
        </label>
        <input
         type="text" value={userData["שם משפחה"]} readOnly
          className={styles.input}
          required
        />
   
   </div>

   <div className={styles.inputGroup}>
      <label htmlFor="שםהסניף" className={styles.label}>
        שם הסניף
      </label>
      <input
        type="text"
        name="שםהסניף"
        placeholder="שם הסניף"
        value={formData.שםהסניף}
        onChange={handleChange}
        className={styles.input}
        required
      />
 
     <label htmlFor="מספרסניף" className={styles.label}>
        מספר הסניף
      </label>
      <input
    type="text" value={userData.סניף} readOnly
        className={styles.input}
        required
      />
       </div>
       <div className={styles.inputGroup}>
         <label htmlFor="תעודתזהות" className={styles.label}>
        תעודת זהות
      </label>
      <input
      type="number" value={idCard} readOnly
      className={styles.input}
      required
      />
         <label htmlFor="טלפון" className={styles.label}>
        מספר טלפון
      </label>
      <input
        type="tel"
        name="טלפון"
        placeholder="הכנס/י מספר טלפון"
        value={formData.טלפון}
        onChange={handleChange}
        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
        className={styles.input}
        required
      />
      </div>
      <div className={styles.inputGroup}>
         <label htmlFor="אימייל" className={styles.label}>
 דואר אלקטרוני -אישי 
      </label>
      <input
        type="email"
        name="אימייל"
        placeholder="הכנס/י אימייל"
        value={formData.אימייל}
        onChange={handleChange}
        className={styles.input}
        required
      />
  
      <label htmlFor="איזור" className={styles.label}>
        איזור
      </label>
      <select
        id="איזור"
        name="איזור"
        onChange={handleSelectChangeArea}
        value={formData.איזור}
        required
        className={styles.input}
      >
        <option value="">לחץ לבחירת איזור</option>
        <option value="תל אביב">תל אביב</option>
        <option value="ירושלים">ירושלים</option>
        <option value="חיפה">חיפה</option>
        <option value="נצרת">נצרת</option>
        <option value="עכו">עכו</option>
        <option value="משרד ראשי">משרד ראשי</option>
      
      </select>
      </div>
    
      <div className={styles.inputGroup}>
         <label htmlFor="אישורדיור" className={styles.label}>
         אני מאשר/ת שליחת הודעות הכוללות אישורים, הנחיות והודעות אחרות לכתובת הדואר אלקטרוני ומספר הטלפון שסיפקתי.
      </label>
      <input
          type="checkbox"
          name="אישורדיור"
          defaultChecked={formData.אישורדיור === 'yes'}
          onChange={handleChangeCoki}
          className={styles.input}
          required
      />
  </div>
   <div/>
    
    </div>

    <div className={styles.inputContainer2}>
      
      <h1  style={{ color: 'red', textAlign: 'center', marginTop: '20px',fontSize:14 }}>הכרטיס בעלות 60 ש"ח לאדם</h1>
      <div className={styles.inputGroup2}>
      {/* <div>Debug: {formData.אימייל}</div> */}
        <label htmlFor="זכאות" className={styles.label}>
          זכאות
        </label>
        <input
type="text" value={userData["זכאות"]} readOnly
     className={styles.input}
  style={{ width:250}} 
  
 
/>
<label htmlFor="מספרכרטיסים" className={styles.label}>
  מספר הכרטיסים
</label>
<select 
  name="מספרכרטיסים"
  value={formData.מספרכרטיסים}
  onChange={handleChange}
  style={{ width:250}} 
  className={styles.input}
>
  {generateOptions(userData["זכאות"]).map((value, index) => 
    <option key={index} value={value}>{value}</option>
  )}
</select>
</div>
<h1  className={styles.title2}>   ילד מתחת לגיל שנתיים אינו מחוייב בכרטיס.<br />
  יש להציג ספח ת.ז בכניסה לאירוע.
</h1>
<h2 className={styles.title2}>לאחר ביצוע הרכישה באתר, אין החזר כספי.</h2>
 
   </div>
   <div className={styles.inputContainer2}>
      
      <h1  style={{ color: 'red', textAlign: 'center', marginTop: '20px',fontSize:14 }}>הסעה בעלות של 10 ש"ח לאדם</h1>
      <div className={styles.inputGroup}>
        <label htmlFor="הסעה" className={styles.label}>
          האם תהיו מעוניינים בהסעה?
        </label>
        <div className={styles.radioButtonGroup}>
       
      <select
        id="הסעה"
        name="הסעה"
        onChange={handleSelectChange2}
        value={formData.הסעה}
        required
        className={styles.input}
      >
        <option value="No">לא</option>
        <option value="Yes">כן</option>
      
      
      
      </select>
        </div>
      </div>
      {showAdditionalOptions && (
        <div className={styles.inputGroup}>
          <label htmlFor="מקוםהסעה" className={styles.label}>
         אנא בחר\י מקום להסעה
      </label>
      <select
        id="מקוםהסעה"
        name="מקוםהסעה"
        onChange={handleSelectChange}
        value={formData.מקוםהסעה}
        required
        className={styles.input}
      >
        <option value="">לחץ לבחירת מקום להסעה </option>
        <option value="עכו -מנהלת אזור עכו ">עכו -מנהלת אזור עכו </option>
        <option value="נצרת-מנהלת איזור נצרת">נצרת- מנהלת איזור נצרת</option>
        <option value="חיפה-נשר">חיפה-נשר</option>
        <option value="ירושלים-סניף רוממה">ירושלים-סניף רוממה</option>
        <option value="דרום-סניף באר שבע">דרום-סניף באר שבע</option>
        <option value="משרד ראשי">משרד ראשי</option>
      
      </select>
      <label htmlFor="מספרנוסעים" className={styles.label}>
      אנא בחר\י מספר נוסעים *בעלות 10 ש"ח לאדם
      </label>
      <select
        id="מספרנוסעים"
        name="מספרנוסעים"
        onChange={handleChange}
        value={formData.מספרנוסעים}
        required={formData.הסעה === "Yes"}
        className={styles.input}
      >
     {generateOptions(formData.מספרכרטיסים).map((value, index) => 
    <option key={index} value={value}>{value}</option>
  )}
      </select>
      </div>
      )}
      <h2 className={styles.title2}>ההסעה מותנית במינימום נרשמים.<br />
 נקודות האיסוף ניתנות לשינוי.</h2>
      </div>
  <div className={styles.inputContainer2}>
      
      <div className={styles.inputGroup}>
        <label htmlFor="הסעה" className={styles.label}>
          האם תרצו מנות גלאט?
        </label>
        <div className={styles.radioButtonGroup}>
       
      <select
        id="מנותגלאט"
        name="מנותגלאט"
        onChange={handleSelectChange3}
        value={formData.מנותגלאט}
        required
        className={styles.input}
      >
        <option value="No">לא</option>
        <option value="Yes">כן</option>
      
      
      
      </select>
        </div>
      </div>
      {showAdditionalOptions2 && (
        <div className={styles.inputGroup}>
      <label htmlFor="כמותמנותגלאט" className={styles.label}>
   אנא בחר\י כמות מנות גלאט
      </label>
      <select
        id="כמותמנותגלאט"
        name="כמותמנותגלאט"
        onChange={handleChange}
        value={formData.כמותמנותגלאט}
        required={formData.מנותגלאט === "Yes"}
        className={styles.input}
      >
     {generateOptions(formData.מספרכרטיסים).map((value, index) => 
    <option key={index} value={value}>{value}</option>
  )}
      </select>
      </div>
      )}
      </div>


   <div className={styles.buttonContainer}>
    <button type="submit" className={[styles.button]}>
     שלח טופס
    </button>

    </div>
  </form>
     ) : (
      // Your success message goes here

      <div style={{ textAlign: 'center', fontSize: '70px',marginBottom:60, }}>
        תודה רבה, בקשתך התקבלה במערכת
      </div>
     
    )}
  </div>
  );
};

export default Form;
